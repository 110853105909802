import React from "react";
import styles from "./transfer.module.css";
import TransferTimer from "../TransferTimer";
import bscLogo from "../../../assets/bscLogo.png";
import ethLogo from "../../../assets/ethLogo.png";
import omcLogo from "../../../assets/omcLogo.png";
import avaxLogo from "../../../assets/avaxLogo.png";
import maticLogo from "../../../assets/maticLogo.png";
import zetaLogo from "../../../assets/zetaLogo.png";

export default function TransferStatus({ status, depositObject }) {
  let finalizing = false;
  let completed = false;
  if (status === 3 || status === 4) {
    finalizing = true;
  }
  if (status === 4) {
    completed = true;
  }

  return (
    <div className={styles.container}>
      <div className={styles.networkContainer}>
        <div className={styles.networkLogo}>
          <img
            className={styles.networkLogo}
            src={
              depositObject.withdraw_network === 21816
                ? omcLogo
                : depositObject.withdraw_network === 1
                ? ethLogo
                : depositObject.withdraw_network === 56
                ? bscLogo
                : depositObject.withdraw_network === 137
                ? maticLogo
                : depositObject.withdraw_network === 7000
                ? zetaLogo
                : avaxLogo
            }
            alt="logo"
          />
        </div>
        <div className={styles.networkText}>Destination Network</div>
      </div>
      <div className={styles.timerContainer}>
        <div className={styles.timerText}>Timer</div>
        <div className={styles.time}>
          <TransferTimer
            status={status}
            confirmedTime={depositObject.deposit_confirmed_time}
            generatedTime={depositObject.ticket_generated_time}
          />
        </div>
      </div>
      <div className={styles.statusContainer}>
        <div className={styles.iconContainer}>
          <div
            className={
              finalizing ? `${styles.statusIconActive}` : `${styles.statusIcon}`
            }
          ></div>
          <div
            className={finalizing ? `${styles.lineActive}` : `${styles.line}`}
          ></div>
          <div className={styles.statusIconTwo}></div>
          <div
            className={
              completed ? `${styles.statusIconActive}` : `${styles.statusIcon}`
            }
          ></div>
        </div>
        <div className={styles.textContainer}>
          <div className={styles.statusText}>Start Transfer</div>
          <div className={styles.statusText}>Completed</div>
        </div>
      </div>
    </div>
  );
}
